import { defineStore } from "pinia"

import Settings from "@/api/Settings"

interface SettingsState {
  title: string
  accentColor: string
  backgroundColor: string
  logo: boolean
  apiUrl: string
}

export const useSettings = defineStore("settings", {
  actions: {
    async updateSettings() {
      const response = await Settings.get()

      if (response.data != null) {
        this.title = response.data.title
        this.accentColor = response.data.accentColor
        this.backgroundColor = response.data.backgroundColor
      } else {
        console.error("Could not update settings")
      }

      const logo = await Settings.getLogo()

      this.logo = logo.data !== null
    },
  },
  state: () =>
    ({
      accentColor: "#000000",
      apiUrl: process.env.VUE_APP_API_URL,
      backgroundColor: "#ffffff",
      logo: false,
      title: "Thingsboard Pipelines",
    } as SettingsState),
})
