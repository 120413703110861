





import { useApiStore } from "@/store/api"
import { useSettings } from "@/store/settings"

export default defineComponent({
  setup() {
    const settings = useSettings()
    const api = useApiStore()

    const logoUrl = computed(() => {
      return `${api.url}/settings/logo`
    })

    return { logoUrl, settings }
  },
})
