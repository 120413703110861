import type { AxiosRequestConfig } from "axios"
import { defineStore } from "pinia"

import type { UserResponse } from "@/api/Auth"
import Auth from "@/api/Auth"
import type { ApiResponse, TokenResponse } from "@/api/Base"
import type { ExecUser } from "@/api/Pipelines"

interface UserState {
  user?: UserResponse
  token: string
  refreshToken: string
}

export const useUser = defineStore("user", {
  actions: {
    async getUserData(): Promise<ApiResponse<UserResponse>> {
      const response = await Auth.me(this.authConfig)

      if (response.data != null) {
        this.user = response.data
      } else if (response.status == 401) {
        this.logout()
      }
      return response
    },
    async login(username: string, password: string) {
      const response = await Auth.login(username, password)

      if (response.data) {
        this.setToken(response.data)
        await this.getUserData()
      }
      return response
    },
    async logout() {
      if (this.isConnected) {
        await Auth.logout(this.authConfig)
      }
      this.$reset()
    },
    setToken({ access_token, refresh_token }: TokenResponse) {
      this.token = access_token
      this.refreshToken = refresh_token
    },
  },
  getters: {
    authConfig(): AxiosRequestConfig {
      return {
        headers: {
          Accept: "*/*",
          Authorization: "Bearer ".concat(this.token),
        },
        withCredentials: true,
      }
    },
    execUser(): ExecUser {
      return {
        id: this.user?.id || "",
        refreshToken: this.refreshToken,
      }
    },
    isAdmin(): boolean {
      const result = this.isConnected && this.user?.scopes.includes("TENANT_ADMIN")
      return !!result
    },
    isConnected(): boolean {
      const result = this.token && this.user
      return !!result
    },
  },
  state: () =>
    ({
      refreshToken: "",
      token: "",
      user: undefined,
    } as UserState),
})
