import type { AxiosRequestConfig, AxiosResponse } from "axios"
import axios from "axios"

import { useApiStore } from "@/store/api"

const errorResponse: AxiosResponse = {
  config: {},
  data: null,
  headers: {},
  status: 0,
  statusText: "UnknownError",
}

export interface TokenResponse {
  access_token: string
  refresh_token: string
  token_type: "bearer"
}

export interface Pagination<T> {
  totalPages: number
  totalElements: number
  data: T[]
}

interface ApiResponseSuccess<T> {
  data: T
  error: null
  status: number
}

interface ApiResponseFail {
  data: null
  error: string
  status: number
}

export type ApiResponse<T> = ApiResponseSuccess<T> | ApiResponseFail

type Handler<T> = ((result: ApiResponse<T>, response: AxiosResponse) => Promise<void>) | string

export type Handlers<T> = Record<number, Handler<T>>

export default class Base {
  public static get basePath(): string {
    const apiStore = useApiStore()
    const url = apiStore.url
    return url.endsWith("/") ? url.slice(0, -1) : url
  }

  public static readonly router: string = ""

  public static async _request(config: AxiosRequestConfig) {
    const newConfig = {
      ...config,
      validateStatus: () => true,
    }

    try {
      const response = await axios.request(newConfig)
      return response
    } catch (error: unknown) {
      return errorResponse
    }
  }

  public static async _get(url: string, config: AxiosRequestConfig) {
    return this._request({
      method: "get",
      url: this.basePath + this.router + url,
      ...config,
    })
  }

  public static async _delete(url: string, config: AxiosRequestConfig) {
    return this._request({
      method: "delete",
      url: this.basePath + this.router + url,
      ...config,
    })
  }

  public static async _post<T>(url: string, data: T, config: AxiosRequestConfig, contentType = "application/json") {
    const newConfig = { headers: {}, ...config }
    newConfig.headers["Content-Type"] = contentType

    return this._request({
      data,
      method: "post",
      url: this.basePath + this.router + url,
      ...newConfig,
    })
  }

  public static async _put<T>(url: string, data: T, config: AxiosRequestConfig, contentType?: string) {
    const settings = { headers: {}, ...config }
    settings.headers["Content-Type"] = contentType ?? "application/json"

    return this._request({
      data,
      method: "put",
      url: this.basePath + this.router + url,
      ...settings,
    })
  }

  public static _apiResponse<T>(status: number): ApiResponse<T> {
    return {
      data: null,
      error: "",
      status,
    }
  }

  public static async _handleResponse<T>(response: AxiosResponse, handlers?: Handlers<T>) {
    const defaultHandlers: Handlers<T> = {
      401: async (result: ApiResponse<T>, response: AxiosResponse) => {
        result.error = "Not logged in"
        await this._handle_401(response.config, result, handlers)
      },
      422: "Validation error",
    }

    handlers ??= {}

    handlers = {
      ...defaultHandlers,
      ...handlers,
    }

    const result = Base._apiResponse<T>(response.status)

    if (response.status in handlers) {
      const handler = handlers[response.status]
      if (handler instanceof Function) {
        await handler(result, response)
      } else {
        result.error = handler
      }
    } else {
      if (response.status < 300 && response.status >= 200) {
        result.data = response.data
        result.error = null
      } else {
        result.error = response.data?.detail ?? response.data?.message ?? response.statusText
      }
    }

    return result
  }

  public static async refresh(refreshToken: string) {
    const response = await Base._post("/auth/refresh", { refreshToken }, {})

    const handlers = {
      401: "Invalid refresh token",
    }

    return Base._handleResponse<TokenResponse>(response, handlers)
  }

  public static async _handle_401<T>(config: AxiosRequestConfig, result: ApiResponse<T>, handlers?: Handlers<T>) {
    const { useUser } = await import("@/store/user")

    const user = useUser()

    handlers ??= {}

    const tokenResponse = await Base.refresh(user.refreshToken)

    if (tokenResponse.data === null) {
      await user.logout()
      return result
    }
    user.setToken(tokenResponse.data)

    config.headers ??= {}

    config.headers.Authorization = "Bearer ".concat(tokenResponse.data.access_token)

    const response = await this._request(config)

    handlers = {
      ...handlers,
      401: async () => {
        await user.logout()
      },
    }

    Object.assign(result, await this._handleResponse<T>(response, handlers))

    return result
  }
}
