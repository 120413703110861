import type { AxiosRequestConfig } from "axios"

import type { Pagination, TokenResponse } from "./Base"
import Base from "./Base"

type UserScopes = "TENANT_ADMIN" | "CUSTOMER_USER" | "SYS_ADMIN"

export interface UserSchema {
  id: string
  customerId: string
  tenantId: string
}

export interface UserResponse extends UserSchema {
  scopes: UserScopes[]
  email: string
}

export type UserPagination = Pagination<UserResponse>

export default class Auth extends Base {
  public static readonly router: string = "/auth"

  public static async login(username: string, password: string) {
    const form = new FormData()
    form.append("grant_type", "password")
    form.append("username", username)
    form.append("password", password)
    form.append("scope", "")
    form.append("client_id", "")
    form.append("client_secret", "")

    const contentType = "application/x-www-form-urlencoded"

    const response = await Auth._post("/login", form, {}, contentType)

    const handlers = {
      401: "Invalid username or password",
    }

    return Auth._handleResponse<TokenResponse>(response, handlers)
  }

  public static async me(auth: AxiosRequestConfig) {
    const response = await Auth._get("/me", auth)

    return Auth._handleResponse<UserResponse>(response)
  }

  public static async logout(auth: AxiosRequestConfig) {
    const response = await Auth._post("/logout", null, auth)

    const handlers = {
      401: "User not logged in",
    }

    return Auth._handleResponse<string>(response, handlers)
  }

  public static async getUsers(auth: AxiosRequestConfig, page: number, pageSize: number, textSearch: string) {
    const config: AxiosRequestConfig = {
      ...auth,
      params: {
        page,
        page_size: pageSize,
        text_search: textSearch,
      },
    }

    const response = await Auth._get("/users", config)

    return Auth._handleResponse<UserPagination>(response)
  }

  public static async getUser(auth: AxiosRequestConfig, userId: string) {
    const response = await Auth._get(`/users/${userId}`, auth)

    return Auth._handleResponse<UserResponse>(response)
  }
}
