















import IndexContent from "@/components/IndexContent.vue" // @ is an alias to /src

export default defineComponent({
  components: {
    IndexContent,
  },
  name: "Home",
})
