import VueCompositionAPI from "@vue/composition-api"
import { PiniaVuePlugin } from "pinia"
import Vue from "vue"
import InfiniteLoading from "vue-infinite-loading"

import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import { pinia } from "./store"

Vue.use(InfiniteLoading)

Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)

Vue.config.productionTip = false

new Vue({
  pinia,
  render: (h) => h(App),
  router,
  vuetify,
}).$mount("#app")
