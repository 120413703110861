import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    options: { customProperties: true, variations: false },
    themes: {
      light: {
        accent: colors.shades.black,
        accentBackground: colors.shades.white,
        background: colors.grey.lighten3,
        backgroundAlt: colors.shades.white,
        primary: colors.shades.black,
        secondary: colors.grey.darken2,
        tertiary: colors.grey.base,
      },
    },
  },
})
