














































import { mdiAccountHardHat, mdiClose, mdiCog, mdiFileDocument, mdiHome, mdiLogin, mdiLogout, mdiPipe } from "@mdi/js"

import Base from "@/api/Base"
import { useUser } from "@/store/user"

import DrawerLogo from "./DrawerLogo.vue"

interface DrawerItem {
  icon: string
  title: string
  to: string
}

export default defineComponent({
  components: { DrawerLogo },
  setup() {
    const user = useUser()
    const drawer = ref<boolean | null>(null)
    const defaultItems: DrawerItem[] = [
      {
        icon: mdiHome,
        title: "Home",
        to: "/",
      },
    ]
    const noAuthItems: DrawerItem[] = [
      {
        icon: mdiLogin,
        title: "Login",
        to: "/login",
      },
    ]
    const authItems: DrawerItem[] = [
      {
        icon: mdiPipe,
        title: "Pipelines",
        to: "/pipelines",
      },
      {
        icon: mdiAccountHardHat,
        title: "Workers",
        to: "/workers",
      },
      {
        icon: mdiLogout,
        title: "Logout",
        to: "/logout",
      },
    ]
    const adminItems: DrawerItem[] = [
      {
        icon: mdiCog,
        title: "Admin settings",
        to: "/admin",
      },
    ]
    const items = computed(() => {
      return [...defaultItems, ...(user.isAdmin ? adminItems : []), ...(user.isConnected ? authItems : noAuthItems)]
    })
    function toggleDrawer() {
      drawer.value = !drawer.value
    }
    const subtitle = computed(() => (user.isConnected ? `Welcome back ${user.user?.email}!` : ""))
    const icons = { mdiClose, mdiFileDocument }
    return {
      API_URL: Base.basePath,
      drawer,
      icons,
      items,
      subtitle,
      toggleDrawer,
      user,
    }
  },
})
