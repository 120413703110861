import { defineStore } from "pinia"

interface ApiStore {
  url: string
}

export const useApiStore = defineStore("api", {
  state: () =>
    ({
      url: process.env.VUE_APP_API_URL,
    } as ApiStore),
})
