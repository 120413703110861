













import { defineComponent, onMounted, ref, watchEffect } from "@vue/composition-api"

import Drawer from "@/components/Drawer.vue"

import { useApiStore } from "./store/api"
import { useSettings } from "./store/settings"
import { useUser } from "./store/user"

export default defineComponent({
  components: { Drawer },
  name: "App",
  setup(_, { root }) {
    const user = useUser()
    const settings = useSettings()
    const apiStore = useApiStore()
    const drawer = ref<InstanceType<typeof Drawer> | null>(null)

    function toggleDrawer() {
      if (drawer.value) {
        drawer.value.toggleDrawer()
      }
    }

    onMounted(() => {
      if (user.isConnected) {
        user.getUserData()
        settings.updateSettings()
      }
    })

    watchEffect(() => {
      root.$vuetify.theme.currentTheme.accent = settings.accentColor
      root.$vuetify.theme.currentTheme.accentBackground = settings.backgroundColor

      const favicon = document.getElementById("favicon")
      if (favicon) {
        favicon.setAttribute("href", `${apiStore.url}/settings/favicon`)
      } else {
        console.error("No favicon found")
      }

      const title = document.getElementById("title")
      if (title && settings.title) {
        title.innerText = settings.title
      } else {
        console.error("No title found")
      }
    })

    return { drawer, settings, toggleDrawer, user }
  },
})
