import type { AxiosRequestConfig } from "axios"

import Base from "./Base"

export interface BasicSettings {
  title: string
  accentColor: string
  backgroundColor: string
}

export default class Settings extends Base {
  public static readonly router: string = "/settings"

  public static async get() {
    const response = await Settings._get("", {})

    return Settings._handleResponse<BasicSettings>(response)
  }

  public static async update(settings: BasicSettings, auth: AxiosRequestConfig) {
    const response = await Settings._put("", settings, auth)

    return Settings._handleResponse<BasicSettings>(response)
  }

  public static async getLogo() {
    const response = await Settings._get("/logo", {})

    return Settings._handleResponse<string>(response)
  }

  public static async putLogo(logo: File, auth: AxiosRequestConfig) {
    const formData = new FormData()
    formData.append("logo", logo)

    const response = await Settings._put("/logo", formData, auth)

    return Settings._handleResponse<string>(response)
  }

  public static async putFavicon(favicon: File, auth: AxiosRequestConfig) {
    const formData = new FormData()
    formData.append("favicon", favicon)

    const response = await Settings._put("/favicon", formData, auth)

    return Settings._handleResponse<string>(response)
  }
}
