import Vue from "vue"
import type { RouteConfig } from "vue-router"
import VueRouter from "vue-router"

import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    component: Home,
    name: "Home",
    path: "/",
  },
  {
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    name: "Login",
    path: "/login",
  },
  {
    component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    name: "Logout",
    path: "/logout",
  },
  {
    component: () => import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    name: "Admin",
    path: "/admin",
  },
  {
    component: () => import(/* webpackChunkName: "pipelines" */ "../views/Pipelines.vue"),
    name: "Pipelines",
    path: "/pipelines",
  },
  {
    component: () => import(/* webpackChunkName: "pipelinecreate" */ "../views/PipelineCreate.vue"),
    name: "PipelineCreate",
    path: "/pipelines/new",
  },
  {
    component: () => import(/* webpackChunkName: "pipelineview" */ "../views/PipelineEdit.vue"),
    name: "PipelineEdit",
    path: "/pipelines/:pipelineId/edit",
  },
  {
    component: () => import(/* webpackChunkName: "pipelineview" */ "../views/PipelineView.vue"),
    name: "PipelineView",
    path: "/pipelines/:pipelineId",
  },
  {
    component: () => import(/* webpackChunkName: "workers" */ "../views/Workers.vue"),
    name: "Workers",
    path: "/workers",
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
})

export default router
